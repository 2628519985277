import React, { useEffect, useState } from "react";
import Navbar from "./Navbar";

import sh from '../Img/sh.jpg';
import fo from '../Img/fo.png';
import maha from '../Img/maha.png';
import eh from '../Img/eh.jpg';
import mani from '../Img/mani.png';
import raj from '../Img/raj.jpg';
import hcg from '../Img/hcg.jpeg';
import Medanta from '../Img/Medanta.png';
import ruku from '../Img/ruku.png';
import Footer from "../Footer";
import { BallTriangle } from "react-loader-spinner";
import { useLocation } from "react-router-dom";

export default function Hospitals() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get('id');
  const [data,setData]=useState([])
  const [loading,setloading]=useState(false)
  useEffect(()=>{
    HospitalCat()
  },[])

  const HospitalCat=async()=>{
    const requestOptions = {
      method: "GET",
      redirect: "follow"
    };
    
    fetch(`https://api.jaishreeramhealthcareprivateltd.com/category/view/${id}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if(result.success==true){
          setData(result.data)
        }
      })
      .catch((error) => console.error(error));
  }
 

  return (
    <div>
      <section>
        <Navbar />
      </section>

      <section>
        <div className="container-fluid">
          {/* Heading Section */}
          <div className="text-center pt-5 pb-3">
            <h1>Top Hospitals</h1>
          </div>

          <div className="row">
          {
            loading ?
              <BallTriangle
                height={50}
                width={50}
                radius={5}
                color="#4fa94d"
                ariaLabel="ball-triangle-loading"
                visible={true}
              /> :
              data.map((res) => (
              <div key={res.id} className="col-lg-4 col-sm-12 col-md-12 mt-4 mb-5">
                <div style={{
                  height: "180px",
                  width: "100%",
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                }}>
                  <img src={res.banner} style={{
                    height: "100%",
                    width: "100%",
                    objectFit: "cover",
                    borderTopLeftRadius: "10px",
                    borderTopRightRadius: "10px"
                  }} />
                </div>
                <div style={{
                  backgroundColor: "#faf5f5",
                  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                  borderBottomRightRadius: "10px",
                  borderEndStartRadius: "10px",
                  padding: "10px"
                }}>
                  <h4>{res.name}</h4>
                  <h6>Address:</h6>
                  <p>{res.address}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      <section className="mt-5">
        <Footer />
      </section>
    </div>
  );
}
