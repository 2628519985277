


import React, { useEffect, useState } from 'react'
import Navbar from './Navbar'
import clip from '../Img/clip.png';
import Footer from '../Footer';
import rajni from '../Img/swati.jpeg';
import de from '../Img/BOOST.png';
import { BallTriangle } from 'react-loader-spinner';
import { useLocation } from 'react-router-dom';

function DroctotCat() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get('id');
  const [loading, setloading] = useState(false)
  const [data, setData] = useState([])

  useEffect(() => {
    drCat()
  },)
  const drCat = async () => {
    setloading(true)
    const requestOptions = {
      method: "GET",
      redirect: "follow"
    };

    fetch(`https://api.jaishreeramhealthcareprivateltd.com/category/view/${id}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.success == true) {
          setData(result.data)
        }
      })
      .catch((error) => console.error(error)).finally(setloading(false))
  }
  return (
    <div>
      <section>
        <Navbar />
      </section>
      <section>
        <div className='container-fluid'>
          <div className='row'>
            <div className="col-lg-4 col-sm-12 col-md-12 position-fixed" style={{ zIndex: 50, }}>
              <div className="card">
                <div className="h-100 w-100">
                  <img
                    src={clip}
                    alt="Clip"
                    style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                  />
                </div>
              </div>
            </div>

            <div className="col-lg-5 col-sm-12 col-md-12 offset-lg-4 scrollable-content" style={{}}>
              {
                loading ?
                  <BallTriangle
                    height={50}
                    width={50}
                    radius={5}
                    color="#4fa94d"
                    ariaLabel="ball-triangle-loading"
                    visible={true}
                  /> :
                  data.map((res) => {
                    return (
                      <div key={res.id} className="card mt-3 mb-3" style={{ flexDirection: "row", gap: 30, backgroundColor: "#ffffff", boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px' }}>
                        <div className='col-4'>
                          <div className='w-100 h-100'>
                            <img src={res.banner} style={{ height: "100%", width: "100%", objectFit: "cover" }} />

                          </div>
                        </div>
                        <div className='col-8'>
                          <h1 style={{ color: "#FF1493", textAlign: "start" }}>{res.name}</h1>
                          <h3 style={{ color: "#FF1493", textAlign: "start" }}>{res.speciality}</h3>
                          <div className="mt-5 buttons-container1">
                            <button className='bt' style={{ border: "none", background: "none", backgroundColor: "#FF1493", color: "white", fontSize: 14, padding: 8, borderRadius: "10px", paddingLeft: 15, paddingRight: 15 }}>View Details</button>
                            <button className='bt' style={{ border: "none", background: "none", backgroundColor: "#FF1493", color: "white", fontSize: 14, padding: 8, borderRadius: "10px", paddingLeft: 15, paddingRight: 15 }}>Request Appointment</button>
                          </div>
                        </div>
                      </div>
                    );
                  })}
            </div>

            <div className="col-lg-3 col-sm-12 col-md-12 position-fixed end-0" style={{ zIndex: 50, }}>
              <div style={{ width: "100%", height: "500px", backgroundSize: "cover", backgroundRepeat: "no-repeat", overflow: "hidden" }}>
                <img src={de} alt="Banner" style={{ width: '100%', height: '100%', objectFit: 'contain', overflow: "hidden" }} />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className='position-sticky top-0' style={{ zIndex: 1000 }}>
        <Footer />
      </section>
    </div>
  )
}

export default DroctotCat

