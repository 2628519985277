import React from "react";
import Navbar from "./Navbar";

function Plans() {
  const data  = [
    {
      free:"free",
      des:"Take it for a test drive. Use it as long as you want.",
      bla:"₹ 0.00",
      freeforever:"free forever",
      banner:"Banner Ads",
      des1:"Small Size banner ad (limited visibility)",
      video:"Video Uploads:",
      des2:" No video uploads",
      btn:"Get Started "
    },
    {
      free:"Lite",
      des:"Gain access to premium features for personal use.",
      bla:"₹ 499",
      freeforever:"Month",
      banner:"Banner Ads",
      des1:"Small Size banner ad (limited visibility)",
      video:"Video Uploads:",
      des2:" No video uploads",
      btn:"Subscribe"
    },
    {
      free:"Pro",
      des:"Become a pro and unlock more powerful banner sizes and video upload",
      bla:"₹ 699",
      freeforever:"Month",
      banner:"Banner Ads",
      des1:"Small Size banner ad (limited visibility)",
      video:"Video Uploads:",
      des2:" No video uploads",
      btn:"Subscribe"
    },
  ]
  const data1  = [
    {
      free:"free",
      des:"Take it for a test drive. Use it as long as you want.",
      bla:"₹ 0.00",
      freeforever:"free forever",
      banner:"Banner Ads",
      des1:"Small Size banner ad (limited visibility)",
      video:"Video Uploads:",
      des2:" No video uploads",
      btn:"Get Started "
    },
    {
      free:"Lite",
      des:"Gain access to premium features for personal use.",
      bla:"₹ 5499",
      freeforever:"Yearly",
      banner:"Banner Ads",
      des1:"Small Size banner ad (limited visibility)",
      video:"Video Uploads:",
      des2:" No video uploads",
      btn:"Subscribe"
    },
    {
      free:"Pro",
      des:"Become a pro and unlock more powerful banner sizes and video upload",
      bla:"₹ 7499",
      freeforever:"Yearly",
      banner:"Banner Ads",
      des1:"Small Size banner ad (limited visibility)",
      video:"Video Uploads:",
      des2:" No video uploads",
      btn:"Subscribe"
    },
  ]
  return <div>
    <section>
      <Navbar />
    </section>
    <section>
      <div className="container pt-2 ">
        <h3 className="text-center text-black">Choose your Monthly Plan</h3>
        <div className="row justify-content-center align-items-center">

        {
          data.map((res)=>{
            return(
              <div className="col-lg-3 col-sm-12 col-md-4 col-12 mt-4">
              <div className="p-2 pt-3 pb-3" style={{
                border: "1px solid #FF0596", borderRadius: 12,
                boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
  
              }}>
                <h5 style={{ fontSize: "30px", color: "black", fontWeight: "700" }}>{res.free}</h5>
                <p style={{ fontSize: "12px", color: "black", fontWeight: "400" }}>{res.des}</p>
                <h5 style={{ fontSize: "30px", color: "black", fontWeight: "700", marginTop: "-10px" }}>{res.bla}</h5>
                <h6 style={{ color: "black", fontWeight: "700", marginTop: "-10px" }}>{res.freeforever}</h6>
                <h6 style={{ color: "black", fontWeight: "700", marginTop: "20px", }}>{res.banner}</h6>
                <p style={{ fontSize: "12px", color: "black", fontWeight: "400", marginTop: "-10px" }}> {res.des1}</p>
                <h6 style={{ color: "black", fontWeight: "700", marginTop: "-15px", }}>{res.video}</h6>
                <p style={{ fontSize: "14px", color: "black", fontWeight: "400", marginTop: "-10px" }}>{res.des2}</p>
                <button
                style={{
                  border: "1px solid #FF0596",
                  borderRadius: "12px",
                  fontSize: "30px",
                  fontWeight: "700",
                  fontFamily: "Lato",
                  width: "100%",
                  background:
                    res.btn === "Subscribe"
                      ? "linear-gradient(90deg, #C60000 0%, #FF0596 100%)"
                      : "none",
                  color: res.btn === "Subscribe" ? "#FFFFFF" : "#000000",
                }}
              >
                {res.btn}
              </button>
              </div>
            </div>
            )
          })
        }
          
        </div>
      </div>
    </section>
    <section>
      <div className="container pt-5 ">
        <h5 className="text-center text-black">Choose your Yearly Plan</h5>
        <div className="row justify-content-center align-items-center">

        {
          data1.map((res)=>{
            return(
              <div className="col-lg-3 col-sm-12 col-md-4 col-12 mt-4">
              <div className="p-2 pt-3 pb-3" style={{
                border: "1px solid #FF0596", borderRadius: 12,
                boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
  
              }}>
                <h5 style={{ fontSize: "30px", color: "black", fontWeight: "700" }}>{res.free}</h5>
                <p style={{ fontSize: "12px", color: "black", fontWeight: "400" }}>{res.des}</p>
                <h5 style={{ fontSize: "30px", color: "black", fontWeight: "700", marginTop: "-10px" }}>{res.bla}</h5>
                <h6 style={{ color: "black", fontWeight: "700", marginTop: "-10px" }}>{res.freeforever}</h6>
                <h6 style={{ color: "black", fontWeight: "700", marginTop: "20px", }}>{res.banner}</h6>
                <p style={{ fontSize: "12px", color: "black", fontWeight: "400", marginTop: "-10px" }}> {res.des1}</p>
                <h6 style={{ color: "black", fontWeight: "700", marginTop: "-15px", }}>{res.video}</h6>
                <p style={{ fontSize: "14px", color: "black", fontWeight: "400", marginTop: "-10px" }}>{res.des2}</p>
                <button
                style={{
                  border: "1px solid #FF0596",
                  borderRadius: "12px",
                  fontSize: "30px",
                  fontWeight: "700",
                  fontFamily: "Lato",
                  width: "100%",
                  background:
                    res.btn === "Subscribe"
                      ? "linear-gradient(90deg, #C60000 0%, #FF0596 100%)"
                      : "none",
                  color: res.btn === "Subscribe" ? "#FFFFFF" : "#000000",
                }}
              >
                {res.btn}
              </button>
              </div>
            </div>
            )
          })
        }
          
        </div>
      </div>
    </section>
  </div>;
}

export default Plans;
