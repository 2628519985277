import React,{useState,useEffect} from "react";
import Navbar from "./Navbar";
import Footer from "../Footer";
import baner from '../Img/baner.png';
import pexel from '../Img/pexel.png';
import lab from '../Img/lab.png';
import dr from '../Img/dr.png';
import hs from '../Img/hs.png';
import Ay from '../Img/Ay.png';
import Yoga from '../Img/yoga.png';
import nat from '../Img/nat.png';
import Fitness from '../Img/Fitness.png';
import homeopathy from '../Img/homeopathy.png';
import healt from '../Img/healt.png';
import plan from '../Img/plan.png';
import front from '../Img/Front.png';
import bg from '../Img/bg.png';
import pro from '../Img/pro.png';
import OTHERS from '../Img/OTHERS.png';
import { Navigate, useNavigate } from "react-router-dom";
import { BallTriangle } from "react-loader-spinner";

function CategeroyCards() {
    const [loading, setloading] = useState(false)
    const [data, setData] = useState([])
    const navigate = useNavigate()
    useEffect(() => {
        category()
    }, [])

    const category = async () => {
        setloading(true)
        const requestOptions = {
            method: "GET",
            redirect: "follow"
        };

        fetch("https://api.jaishreeramhealthcareprivateltd.com/category/view", requestOptions)
            .then((response) => response.json())
            .then((result) => {
                console.log(result)
                if (result.success === true) {
                    setData(result.data)
                }
            })
            .catch((error) => console.error(error)).finally(
                setloading(false)
            )
    }


    const handleNavigation = (categoryName, id) => {
        if (categoryName === "Doctors") {
            navigate(`/DroctotCat?id=${id}`);
        } else {
            navigate(`/Hospitlas?id=${id}`);
        }
    };

  
  return <div>
  <section>
  <Navbar/>
  </section>
  <section>
  <div className="container-fluid pt-4">
      <div id="carouselExampleSlidesOnly" className="carousel slide" data-bs-ride="carousel">
          <div className="carousel-inner">
              <div className="carousel-item active">
                  <div style={{ width: "100%", backgroundSize: "cover", objectFit: "cover" }}>
                      <img src={baner} style={{ height: "100%", width: "100%" }} />
                  </div>
              </div>
              <div className="carousel-item">
                  <div style={{ width: "100%", backgroundSize: "cover", objectFit: "cover" }}>
                      <img src={baner} style={{ height: "100%", width: "100%" }} />
                  </div>
              </div>
              <div className="carousel-item">
                  <div style={{ width: "100%", backgroundSize: "cover", objectFit: "cover" }}>
                      <img src={baner} style={{ height: "100%", width: "100%" }} />
                  </div>
              </div>
          </div>
      </div>
  </div>
</section>
<section>
                <div className="container">
                    <div className="row pt-5 expolore">
                        <div className="  top pe-5 d-flex justify-content-between ">
                            <h3 style={{ fontFamily: "Inter", color: "#FF1493", fontWeight: "600" }}>Explore Categories</h3>
                            <p onClick={()=>navigate("/CategeroyCards")} style={{ fontFamily: "Inter", color: "#FF1493", fontWeight: "600",textDecorationLine:"underline"}}>Sell All</p>
                        </div>
                        {
                            loading
                                ?
                                <BallTriangle
                                    height={50}
                                    width={50}
                                    radius={5}
                                    color="#4fa94d"
                                    ariaLabel="ball-triangle-loading"
                                    visible={true}
                                /> :
                                data.map((res) => (
                                    <button
                                        key={res.id}
                                        style={{ border: "none", background: "none" }}
                                        onClick={() => handleNavigation(res.name, res.id)}
                                        className="col-lg-2 col-md-6 col-sm-6 col-6 mb-4"
                                    >
                                        <div style={{
                                            backgroundColor: "#FF1493",
                                            borderRadius: "10px",
                                            overflow: "hidden"
                                        }}>

                                            <div style={{ height: "130px", width: "100%" }}>
                                                <img
                                                    src={res.banner}
                                                    style={{
                                                        height: "100%",
                                                        width: "100%",
                                                        objectFit: "cover"
                                                    }}
                                                />
                                            </div>
                                            <div
                                                style={{
                                                    backgroundColor: "#FF1493",

                                                }}
                                            >
                                                <p className="fs-5 text-white p-2" style={{ textAlign: "center" }}>
                                                    {res.name}
                                                </p>
                                            </div>
                                        </div>
                                    </button>
                                ))}

                    </div>
                </div>
            </section>

            <section>
            <Footer/>
            </section>
  </div>;
}

export default CategeroyCards;
