import React from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import jai from '../Img/jai.png';
import user from '../Img/user.png';


function Navbar() {
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem('token');
    navigate('/landing'); 
  };
  return (
    <div style={{marginBottom:"90px"}}>
    <nav className="navbar navbar-expand-lg fixed-top mb-5" style={{backgroundColor:"#FF0596"}}>
    <div className="container-fluid">
      <a className="navbar-brand" onClick={()=>navigate("/home")} href="#">
      <img src={jai} alt="Logo" className="navbar-logo" />
      
      </a>
      <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon"></span>
      </button>
      <div className="collapse navbar-collapse" id="navbarSupportedContent">
        <ul className="navbar-nav ms-auto mb-2 mb-lg-0" >
          <li className="nav-item" onClick={()=>navigate("/videoUpload")}>
            <a className="nav-link active fs-5" aria-current="page" href="#">About us</a>
          </li>
          <li className="nav-item"  onClick={()=>navigate("/plans")}>
            <a className="nav-link active fs-5" aria-current="page" href="#">Contact us</a>
          </li>
          <li className="nav-item" onClick={handleLogout}>
            <a className="nav-link active fs-5" aria-current="page" href="#">Log Out</a>
          </li>
            <a className="nav-link active" onClick={()=>navigate('/UserProfile')} aria-current="page" href="#">
            <img src={user}  alt="Logo" style={{height:30,width:50,objectFit:"contain"}} />

            </a>

        </ul>
       
      </div>
    </div>
  </nav>
    </div>
  );
}

export default Navbar;
