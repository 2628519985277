import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaUser, FaTransgender, FaGem, FaLocationArrow, FaHandHoldingMedical, FaBriefcaseMedical } from "react-icons/fa";
import { IoCall } from "react-icons/io5";
import { BiCategoryAlt } from "react-icons/bi";
import { Navigate, useNavigate } from "react-router-dom";
import loinback from "../Compontes/Img/loginBack.png";
import './login.css';
import { flushSync } from "react-dom";
import { BallTriangle } from "react-loader-spinner";
const SignUpForm = () => {
  
  const [name, setName] = useState("");
  const [gender, setGender] = useState("");
  const [age, setAge] = useState("");
  const [address, setAddress] = useState("");
  const [bloodGroup, setBloodGroup] = useState("");
  const [profession, setProfession] = useState("");
  const [category, setCategory] = useState("");
  const [number, setNumber] = useState("");
  const [insurance, setInsurance] = useState("");
  const [insuranceType, setInsuranceType] = useState("");
  const [insuranceCompany, setInsuranceCompany] = useState("");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault();

    if (
      !name ||
      !gender ||
      !age ||
      !address ||
      !bloodGroup ||
      !profession ||
      // !category ||
      !number
    ) {
      toast.error("Please fill in all required fields", {
        autoClose: 3000,
      });
      return;
    }

    if (number.length !== 10) {
      toast.error("Contact number must be 10 digits long", {
        autoClose: 3000,
      });
      return;
    }

    signUp();
  };

  const signUp = async () => {
    setLoading(true);
    const formdata = new FormData();
    formdata.append("name", name);
    formdata.append("profession", profession);
    // formdata.append("category", category);
    formdata.append("bloodgroup", bloodGroup);
    formdata.append("contact", number);
    formdata.append("age", age);
    formdata.append("address", address);
    formdata.append("gender", gender);
  
    const requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow"
    };
  
    try {
      const response = await fetch("https://api.jaishreeramhealthcareprivateltd.com/users/Sign-Up", requestOptions);
      const result = await response.json();
      if (result.success === true) {
        localStorage.setItem("token", result.token);
        console.log(result.token)
        navigate("/home");

        toast.success(result.message, {
          autoClose: 3000,
        });
      } else {
        toast.error(result.err, {
          autoClose: 3000,
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  





  return (
    <div style={{ overflowX: "hidden" }}>
      <ToastContainer />
      <div
        className="container-fulid imgsee"
        style={{
          backgroundImage: `url(${loinback})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundAttachment: "fixed",
          alignItems: "center",
        }}
      >
        <div style={{ backgroundColor: "rgba(0, 0, 0, 0.6)", justifyContent: "center", alignItems: "center", display: "flex" }}>
          <form className="form" onSubmit={handleSubmit}>
            <div className="input-container">
              <FaUser size={24} />
              <input
                className="input"
                type="text"
                placeholder="Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className="input-container">
              <IoCall size={24} />
              <input
                className="input"
                type="number"
                placeholder="Number"
                value={number}
                onChange={(e) => {
                  if (e.target.value.length <= 10) {
                    setNumber(e.target.value);
                  }
                }}
                maxLength={10} 
              />
            </div>
            <div className="input-container">
              <FaTransgender size={24} />
              <input
                className="input"
                type="text"
                placeholder="Gender"
                value={gender}
                onChange={(e) => setGender(e.target.value)}
              />
            </div>
            <div className="input-container">
              <FaGem size={24} />
              <input
                className="input"
                type="number"
                placeholder="Age"
                value={age}
                onChange={(e) => setAge(e.target.value)}
              />
            </div>
            <div className="input-container">
              <FaLocationArrow size={24} />
              <input
                className="input"
                type="text"
                placeholder="Address"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
              />
            </div>
            <div className="input-container">
              <FaHandHoldingMedical size={24} />
              <input
                className="input"
                type="text"
                placeholder="Do you have Insurance?"
                value={insurance}
                onChange={(e) => setInsurance(e.target.value)}
              />
            </div>
            <div className="input-container">
              <FaHandHoldingMedical size={24} />
              <input
                className="input"
                type="text"
                placeholder="Which type of insurance?"
                value={insuranceType}
                onChange={(e) => setInsuranceType(e.target.value)}
              />
            </div>
            <div className="input-container">
              <FaHandHoldingMedical size={24} />
              <input
                className="input"
                type="text"
                placeholder="Which company insurance?"
                value={insuranceCompany}
                onChange={(e) => setInsuranceCompany(e.target.value)}
              />
            </div>
            <label className="label">Blood Group</label>
            <div className="blood-group-container">
              {['A+', 'A-', 'B+', 'B-', 'O+', 'O-', 'AB+', 'AB-'].map((group, index) => (
                <button
                  key={group}
                  type="button"
                  className={`blood-group-button ${bloodGroup === group ? 'selected' : ''}`}
                  onClick={() => setBloodGroup(group)}
                  style={index < 4 ? { marginRight: "2%" } : {}}
                >
                  {group}
                </button>
              ))}
            </div>
            <div className="input-container">
              <FaBriefcaseMedical size={24} />
              <input
                className="input"
                type="text"
                placeholder="Profession"
                value={profession}
                onChange={(e) => setProfession(e.target.value)}
              />
            </div>
           {/* <div className="input-container">
              <BiCategoryAlt size={24} />
              <input
                className="input"
                type="text"
                placeholder="Category"
                value={category}
                onChange={(e) => setCategory(e.target.value)}
              />
            </div>*/}
            <button type="submit" className="button" disabled={loading} style={{ backgroundColor: "#0539FF",justifyContent:"center",alignItems:"center",display:"flex" }}>
              {loading ?<div></div> : "Signing Up"}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default SignUpForm;