import React, { useEffect, useState } from "react";
import Navbar from "./Navbar";
import baner from '../Img/baner.png';

import healt from '../Img/healt.png';
import plan from '../Img/plan.png';
import front from '../Img/Front.png';
import bg from '../Img/bg.png';
import pro from '../Img/pro.png';
import malthora from '../Img/malthora.png';
import malthorabaner from '../Img/9999.png'
import mii from '../Img/mii.png'
import './home.css';
import { Navigate, useNavigate } from "react-router-dom";
import Footer from "../Footer";
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaWhatsapp } from 'react-icons/fa';
import video from '../Img/video.mp4'
import one from '../Img/101.png';
import one1 from '../Img/102.png';
import one2 from '../Img/103.png';
import one3 from '../Img/104.png';


import video1 from '../Img/jsrv2.mp4'
import { BallTriangle } from "react-loader-spinner";
function Home() {
    const [loading, setloading] = useState(false)
    const [data, setData] = useState([])


    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 4,
                },
            },
        ],
    };

    const firstslide = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    };
    const firstslide2 = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    };

    const firstslide22 = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                },
            },
        ],
    };
    const navigate = useNavigate()
    useEffect(() => {
        category()
    }, [])

    const category = async () => {
        setloading(true)
        const requestOptions = {
            method: "GET",
            redirect: "follow"
        };

        fetch("https://api.jaishreeramhealthcareprivateltd.com/category/view", requestOptions)
            .then((response) => response.json())
            .then((result) => {
                console.log(result)
                if (result.success === true) {
                    setData(result.data)
                }
            })
            .catch((error) => console.error(error)).finally(
                setloading(false)
            )
    }


    const handleNavigation = (categoryName, id) => {
        if (categoryName === "Doctors") {
            navigate(`/DroctotCat?id=${id}`);
        } else {
            navigate(`/Hospitlas?id=${id}`);
        }
    };
    return (
        <div style={{ overflowX: "hidden" }}>
            <section>
                <Navbar />
            </section>

            <section>
                <div className="container-fluid pt-4">
                    <div className="slider-container">
                        <Slider {...firstslide}>
                            <div className="slider-item">
                                <img src={baner} className="slider-img" />
                            </div>

                            <div className="slider-item">
                                <img src={malthora} className="slider-img" />
                            </div>
                            <div className="slider-item">
                                <img src={baner} className="slider-img" />
                            </div>
                        </Slider>
                    </div>
                </div>
            </section>


            <section>
                <div className="container">
                    <div className="row pt-5 expolore">
                        <div className="  top pe-5 d-flex justify-content-between ">
                            <h3 style={{ fontFamily: "Inter", color: "#FF1493", fontWeight: "600" }}>Explore Categories</h3>
                            <p onClick={() => navigate("/CategeroyCards")} style={{ fontFamily: "Inter", color: "#FF1493", fontWeight: "600", textDecorationLine: "underline" }}>Sell All</p>
                        </div>
                        {
                            loading
                                ?
                                <BallTriangle
                                    height={50}
                                    width={50}
                                    radius={5}
                                    color="#4fa94d"
                                    ariaLabel="ball-triangle-loading"
                                    visible={true}
                                /> :
                                data.map((res) => (
                                    <button
                                        key={res.id}
                                        style={{ border: "none", background: "none" }}
                                        onClick={() => handleNavigation(res.name, res.id)}
                                        className="col-lg-2 col-md-6 col-sm-6 col-6 mb-4"
                                    >
                                        <div style={{
                                            backgroundColor: "#FF1493",
                                            borderRadius: "10px",
                                            overflow: "hidden"
                                        }}>

                                            <div style={{ height: "130px", width: "100%" }}>
                                                <img
                                                    src={res.banner}
                                                    style={{
                                                        height: "100%",
                                                        width: "100%",
                                                        objectFit: "cover"
                                                    }}
                                                />
                                            </div>
                                            <div
                                                style={{
                                                    backgroundColor: "#FF1493",

                                                }}
                                            >
                                                <p className="fs-5 text-white p-2" style={{ textAlign: "center" }}>
                                                    {res.name}
                                                </p>
                                            </div>
                                        </div>
                                    </button>
                                ))}

                    </div>
                </div>
            </section>

            <section className="pt-5 expolore">
                <div className="top spot ps-5 pe-5">
                    <h3 style={{ fontFamily: "Inter", color: "#FF1493", fontWeight: "600" }}>In spotlight</h3>
                </div>
                <div className="container-fluid ex p-5" style={{ backgroundColor: "#FF1496" }}>
                    <div className="slider-container">
                        <Slider {...firstslide}>
                            <div className="slider-item">
                                <img src={healt} className="slider-img" /></div>

                            <div className="slider-item">
                                <img src={healt} className="slider-img" /></div>
                            <div className="slider-item">
                                <img src={healt} className="slider-img" /></div>
                        </Slider>
                    </div>
                    </div>
            </section>

            <section>
                <div className="top pt-5 spot ps-5">
                    <h3 style={{ fontFamily: "Inter", color: "#FF1493", fontWeight: "600" }}>TOP Plans</h3>
                </div>

                <div className="container-fluid" >
                    <div className="row" >
                    <div className="slider-container">
                            <Slider {...firstslide22}>
                                <div className="col-lg-5 col-5">
                                    <div className="data" style={{ width: "95%", backgroundSize: "cover",height:270,overflow:"hidden",borderRadius:10,backgroundRepeat:"no-repeat"  }}>
                                        <img src={plan} style={{ height: "100%", width: "100%",objectFit:"contain",borderRadius:10 }} />
                                    </div>
                                </div>

                                <div className="col-lg-5 col-5">
                                <div className="data" style={{ width: "95%", backgroundSize: "cover",height:270,overflow:"hidden",borderRadius:10  }}>
                                <img src={plan} style={{ height: "100%", width: "100%",objectFit:"contain" }} />
                            </div>
                                </div>


                            </Slider>
                        </div>

                    </div>
                </div>
            </section>
            <section>
                <div className="container-fluid">
                    <div className="row best pt-5 pb-3">

                        <div className="slider-container">
                            <Slider {...settings}>

                                <div className="card" style={{ border: "1px solid red" }}>
                                    <div>
                                        <img src={front} style={{ height: "100%", width: "100%",objectFit:"contain" }} />
                                    </div>
                                </div>
                                <div className="card">
                                    <div>
                                        <img src={front} style={{ height: "100%", width: "100%" }} />
                                    </div>
                                </div>
                                <div className="card">
                                    <div>
                                        <img src={front} style={{ height: "100%", width: "100%" }} />
                                    </div>
                                </div>
                                <div className="card">
                                    <div>
                                        <img src={front} style={{ height: "100%", width: "100%" }} />
                                    </div>
                                </div>

                            </Slider>
                        </div>
                    </div>


                </div>
            </section>
            <section className="pt-5  topimg">
                <div className="container">
                    <div className="row align-items-center">
                        {/* 50% for Heading and Description */}
                        <div className="col-lg-6 col-md-6 col-sm-12 text-center">
                            <h2 style={{ color: "#FF1493", fontSize: "36px", fontWeight: "bold" }}>Our Services</h2>
                            <p style={{ fontSize: "18px", color: "#333", marginTop: "20px" }}>
                                Explore the best healthcare services with top-notch facilities. Our dedicated team
                                ensures you get the best care possible.
                            </p>
                        </div>


                        <div className="col-lg-6 col-md-6 col-sm-12 text-center">
                            <video width="100%" height="300" controls >
                                <source src={video} type="video/mp4" />
                            </video>
                        </div>
                    </div>
                </div>
            </section>

            {/* {/* 50% for Video */}

            <section>
                <div className="container-fluid">
                    <div className="row best pt-5 pb-3">


                        <div className="slider-container">
                            <Slider {...settings}>

                                <div className="card" style={{ border: "1px solid red" }}>
                                    <div>
                                        <img src={front} style={{ height: "100%", width: "100%" }} />
                                    </div>
                                </div>
                                <div className="card">
                                    <div>
                                        <img src={front} style={{ height: "100%", width: "100%" }} />
                                    </div>
                                </div>
                                <div className="card">
                                    <div>
                                        <img src={front} style={{ height: "100%", width: "100%" }} />
                                    </div>
                                </div>
                                <div className="card">
                                    <div>
                                        <img src={front} style={{ height: "100%", width: "100%" }} />
                                    </div>
                                </div>

                            </Slider>
                        </div>
                    </div>


                </div>
            </section>


            <section>
                <div className="container-fluid">
                    <div className="row best pt-5 pb-3">


                        <div className="slider-container">
                            <Slider {...settings}>
                                <div className="card1" style={{ border: "1px solid red" }}>
                                    <div>
                                        <img src={front} style={{ width: "100%", aspectRatio: "3 / 4",objectFit: "cover"  }} />
                                    </div>
                                </div>
                                <div className="card1">
                                    <div>
                                        <img src={front} style={{ width: "100%", aspectRatio: "3 / 4", objectFit: "cover" }} />
                                    </div>
                                </div>
                                <div className="card1">
                                    <div>
                                        <img src={front} style={{ width: "100%", aspectRatio: "3 / 4", objectFit: "cover" }} />
                                    </div>
                                </div>
                                <div className="card1">
                                    <div>
                                        <img src={front} style={{ width: "100%", aspectRatio: "3 / 4", objectFit: "cover" }} />
                                    </div>
                                </div>
                            </Slider>
                        </div>
                    </div>
                </div>
            </section>


            <section>
                <div className="container-fluid topimg pt-5 ps-4 pe-4 ">

                    <div className="row">
                        <div className="top pt-5 spot ps-5">
                            <h3 style={{ fontFamily: "Inter", color: "#FF1493", fontWeight: "600" }}>Best Offers</h3>
                        </div>
                        <div className="col-lg-12 pt-4 col-sm-12 col-md-12">


                            <div className="slider-container">
                                <Slider {...firstslide2}>

                                    <div style={{ width: "100%", backgroundSize: "cover", objectFit: "cover" }}>
                                        <img src={bg} style={{ height: "100%", width: "100%" }} />
                                    </div>
                                    <div style={{ width: "100%", backgroundSize: "cover", objectFit: "cover" }}>
                                        <img src={malthorabaner} style={{ height: "100%", width: "100%" }} />
                                    </div>
                                    <div style={{ width: "100%", backgroundSize: "cover", objectFit: "cover" }}>
                                        <img src={mii} style={{ height: "100%", width: "100%" }} />
                                    </div>

                                </Slider>
                            </div>

                        </div>
                    </div>
                </div>
            </section>

            <section className="pt-5">
                <div className="container-fluid  topimg pt-5 pb-5 p-3">
                    <img src={pro} style={{ height: "100%", width: "100%" }} />
                </div>
            </section>

            <section className="pt-5  topimg">
                <div className="container">
                    <div className="row align-items-center">
                        {/* 50% for Heading and Description */}
                        <div className="col-lg-6 col-md-6 col-sm-12 text-center">
                            <h2 style={{ color: "#FF1493", fontSizez: "36px", fontWeight: "bold" }}>Our Services</h2>
                            <p style={{ fontSize: "18px", color: "#333", marginTop: "20px" }}>
                                Explore the best healthcare services with top-notch facilities. Our dedicated team
                                ensures you get the best care possible.
                            </p>
                        </div>

                        {/* 50% for Video */}
                        <div className="col-lg-6 col-md-6 col-sm-12 text-center">
                            <video width="100%" height="300" controls >
                                <source src={video1} type="video/mp4" />
                            </video>
                        </div>
                    </div>
                </div>
            </section>

            {/* Final Image Grid Section */}
            <section>
                <div className="container-fluid">
                    <div className="row pt-5">
                        <div className="slider-container">
                            <Slider {...settings}>

                                <div className="card">
                                    <div>
                                        <img src={front} style={{ height: "100%", width: "100%" }} />
                                    </div>
                                </div>
                                <div className="card">
                                    <div>
                                        <img src={front} style={{ height: "100%", width: "100%" }} />
                                    </div>
                                </div>
                                <div className="card">
                                    <div>
                                        <img src={front} style={{ height: "100%", width: "100%" }} />
                                    </div>
                                </div>
                                <div className="card">
                                    <div>
                                        <img src={front} style={{ height: "100%", width: "100%" }} />
                                    </div>
                                </div>

                            </Slider>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid">
                    <div className="row best pt-5 pb-3">


                        <div className="slider-container">
                            <Slider {...settings}>
                                <div className="card1" style={{ border: "1px solid red" }}>
                                    <div>
                                        <img src={one3} style={{ width: "100%", aspectRatio: "1 / 1", objectFit: "cover" }} />
                                    </div>
                                </div>
                                <div className="card1">
                                    <div>
                                        <img src={one2} style={{ width: "100%", aspectRatio: "1 / 1", objectFit: "cover" }} />
                                    </div>
                                </div>
                                <div className="card1">
                                    <div>
                                        <img src={one1} style={{ width: "100%", aspectRatio: "1 / 1", objectFit: "cover" }} />
                                    </div>
                                </div>
                                <div className="card1">
                                    <div>
                                        <img src={one} style={{ width: "100%", aspectRatio: "1 / 1", objectFit: "cover" }} />
                                    </div>
                                </div>
                            </Slider>
                        </div>
                    </div>
                </div>
            </section>



            <a
                href="https://wa.me/9653778555"
                target="_blank"
                rel="noopener noreferrer"
                style={{
                    position: 'fixed',
                    bottom: '20px',
                    right: '20px',
                    backgroundColor: '#25D366',
                    color: 'white',
                    borderRadius: '50%',
                    width: '60px',
                    height: '60px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    zIndex: 1000,
                }}
            >
                <FaWhatsapp size={30} />
            </a>
            {/* Footer Section */}
            <section className="mt-5">
                <Footer />
            </section>
        </div>
    );
}

export default Home;