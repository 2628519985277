import React from "react";
import Navbar from "./Navbar";
import '././upload.css'
function UploadImage() {
  return <div>
  <section>
  <Navbar/>
  </section>
  <section>
  <div className="container">
  <div className="row pt-4">
  <div className="col-lg-6 col-sm-12 col-md-12 mx-auto mb-5" style={{
  boxShadow: '#C60000 0px 0px 0px 3px, #C60000 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset',borderRadius:5,backgroundColor:"#f5f0f0"}}
  >
<div className="m-4">
<select className="form-select" style={{ backgroundColor: "#FF0596", color: "white", display: "flex" }} aria-label="Default select example">
<option style={{backgroundColor:"white",color:"#C71585",border:"1px solid gray", fontsize:21,borderRadius:10}} className="data" selected>select category</option>
<option style={{backgroundColor:"white",color:"#C71585",border:"1px solid gray", fontsize:21,borderRadius:10}} className="data" value="1">In spotlight </option>
<option style={{backgroundColor:"white",color:"#C71585",border:"1px solid gray", fontsize:21,borderRadius:10}} className="data" value="2">Top plans</option>
<option style={{backgroundColor:"white",color:"#C71585",border:"1px solid gray", fontsize:21,borderRadius:10}} className="data" value="3">Best offers</option>
</select>
<select className="form-select mt-4" style={{ backgroundColor: "#FF0596", color: "white" }} aria-label="Default select example">
<option style={{backgroundColor:"white",color:"#C71585",border:"1px solid gray", fontsize:21,borderRadius:10}} selected>Select size</option>
<option style={{backgroundColor:"white",color:"#C71585",border:"1px solid gray", fontsize:21,borderRadius:10}} value="1">2:1</option>
<option style={{backgroundColor:"white",color:"#C71585",border:"1px solid gray", fontsize:21,borderRadius:10}} value="2">4:1</option>
<option style={{backgroundColor:"white",color:"#C71585",border:"1px solid gray", fontsize:21,borderRadius:10}} value="3">3:4</option>
</select>
<div className="pt-5">
<h5 style={{color:"#FF0596"}}>URL</h5>
<input className="w-100 p-1" style={{border:"1px solid #C71585 ",borderRadius:5,outline:"none"}} placeholder="Paste your url here" />
</div>
<div className="pt-1">
<h5 style={{color:"#FF0596"}}>Image file</h5>
<div className="p-5" style={{border:"1px solid #C71585 ",borderRadius:5,display:"flex",justifyContent:"center",alignItems:"center"}}>
<button style={{background:"none",border:"none",display:"flex"}}><p style={{color:"blue",paddingRight:5}}>Choose a file </p> or Drope here</button>
</div>
</div>
<button className="w-100 p-1 mt-5 " style={{background:"#FF0596",border:"none",color:"white",fontSize:21,borderRadius:10}}>Upload</button>
</div>
  </div>
  </div>
  </div>
  </section>
  </div>;
}

export default UploadImage;
