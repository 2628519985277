import React, { useEffect, useState } from 'react';
import './Loginscrren.css';
import jai from '../Compontes/Img/jai.png';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { BallTriangle } from 'react-loader-spinner';
import { data, param } from 'jquery';

const LoginScreen = () => {
  const navigate = useNavigate();
  const [contact, setContact] = useState("");
  const [loading, setLoading] = useState(false);



  const otpSend = async () => {
    const contactRegex = /^\d{10}$/; 
  
    if (contact.length === 0) {
      toast.error("Please fill in the contact number", {
        autoClose: 3000,
      });
      return;
    }
  
    if (!contactRegex.test(contact)) { 
      toast.error("Please enter a valid 10-digit contact number", {
        autoClose: 3000,
      });
      return; 
    }
  
    setLoading(true); 
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
  
    const raw = JSON.stringify({
      contact: contact
    });
  
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow"
    };
  
    try {
      const response = await fetch("https://api.jaishreeramhealthcareprivateltd.com/users/otp", requestOptions);
      const result = await response.json();
  
      if (result.success === true) { 
        toast.success(result.message, {
          autoClose: 3000,
        });
        navigate("/LoginOtp", { state: { contact: contact }});
        setContact(""); 
      } else {
        toast.error(result.error, { 
          autoClose: 3000,
        });
      }
    } catch (error) {
      toast.error(error, {
        autoClose: 3000,
      });
    } finally {
      setLoading(false);
    }
  };
  
  
  
  return (
    <div style={{overflowX:"hidden"}}>
    <ToastContainer/>
    <div className="login-container">
      <div className="login-left-pane">
        <div className="login-logo">
          <div className="login-logo1">
            <img src={jai} alt="jai" style={{ height: "100%", width: "100%", objectFit: "cover" }} />
          </div>
        </div>
        <h1 className="pt-3">Welcome<br />Jai Shree Ram Healthcare<br />Pvt. Ltd.!</h1>
        <p className="login-description pt-3">
          Your one-stop solution for comprehensive healthcare services. We believe in providing prompt and personalized care to all our patients, ensuring that when you need us, we’re always there with the answers you seek.
        </p>
      </div>
      <div className="login-right-pane">
        <div className="login-form-container">
          <h3 style={{ textAlign: "center", paddingTop: "100px", fontSize: "32px", color: "#FF1493" }}>Login</h3>
          <div style={{ paddingTop: "50px" }}>
            <h2>Enter your mobile number</h2>
            <div className="login-input-container">
              <input type="number" placeholder="+91 012 345 6789" value={contact}
                onChange={(e) => {
                  if (e.target.value.length <= 10) {
                    setContact(e.target.value);
                  }
                }}
                className="login-input" maxLength={10} />
            </div>
            <div style={{ padding: "19px", paddingTop: "10px", alignContent: "center", justifyContent: "center", display: "flex" }}>
            <button
              style={{
                backgroundColor: "#FF1493",
                width: "100%",
                padding: "10px",
                color: "white",
                fontSize: "17px",
                border: "none",
                borderRadius: "20px",
                display: "flex",          
                alignItems: "center",     
                justifyContent: "center", 
              }}
              onClick={otpSend}
            >
              {loading ? (
                <BallTriangle
                  height={50}               
                  width={50}                
                  radius={5}
                  color="#4fa94d"
                  ariaLabel="ball-triangle-loading"
                  visible={true}
                />
              ) : (
                "Continue"
              )}
            </button>
          </div>
          
          </div>
        </div>
      </div>
    </div>
    </div>
  );
}

export default LoginScreen;
