import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import frame from "../Compontes/Img/frame1.png";
import close from "../Compontes/Img/Close.png";
import happy from "../Compontes/Img/Happy.png";
import background from "../Compontes/Img/background.png";
import Artificial from "../Compontes/Img/Artificial.png";
import helthcareb from "../Compontes/Img/helthcareb.png";
import Hospitalss from "../Compontes/Img/Hospitalss.png";
import Insurance from "../Compontes/Img/Insurance.png";
import Medi from "../Compontes/Img/Medi.png";
import Next from "../Compontes/Img/Next.png";
import Treatment from "../Compontes/Img/Treatment.png";

import './style.css';

const ResponsiveSlider = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const cards = [
    { text: "Medicines Home Delivery", backgroundColor: "#FFD1DC", img: frame, des: "Get your prescriptions delivered right to your doorstep with ease and reliability." },
    { text: "Lab Test Bookings", backgroundColor: "#FFD1DC", img: close, des: "Schedule your lab tests with us for a hassle-free experience and timely results." },
    { text: "Physiotherapy Services", backgroundColor: "#FFD1DC", img: happy, des: "With 8 centers across Jaipur, our expert physiotherapists are here to help you recover and stay healthy." },
    { text: "Doctor Consultations", backgroundColor: "#FFD1DC", img: Artificial, des: "Choose between online or offline consultations with experienced doctors for all your healthcare needs." },
    { text: "Insurance Policies", backgroundColor: "#FF69B4", img: frame, des: "Secure your health with our comprehensive insurance plans tailored to your requirements." },
    { text: "Hospital Admissions", backgroundColor: "#FF69B4", img: helthcareb, des: "We facilitate smooth and quick hospital admissions to ensure you get the care you need without any delays." },
    { text: "Healthcare Services Bookings", backgroundColor: "#FFD1DC", img: Hospitalss, des: "Easily book a wide range of healthcare services through our user-friendly platform." },
    { text: "Personal VIP Healthcare Assistance", backgroundColor: "#FFD1DC", img:Insurance, des: "Description for Personal VIP Healthcare Assistance" },
    { text: "Artificial Intelligence Assistant", backgroundColor: "#FFD1DC", img: Medi, des: "Our AI assistant is here to guide you through your healthcare journey, providing you with timely information and support." },
    { text: "NextDay Service Booking System", backgroundColor: "#FFD1DC", img: Next, des: "Book any healthcare service for the very next day, ensuring you get the care you need promptly." },
    { text: "Treatment Assistant Guidance Services", backgroundColor: "#FFD1DC", img: Treatment, des: "Receive expert guidance on your treatment plans and healthcare needs." },
  ];

  return (
    <section>
      <div
        className="container-fluid pt-5 mb-5"
        style={{
          backgroundImage: `url(${background})`,
          width: "100%",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundAttachment: "fixed",
          height: "100%",
        }}
      >
        <div className="text-center mb-4">
          <h3 style={{ color: "#C71585", fontSize: "32px" }}>Our Services</h3>
          <h4 style={{ color: "#C71585", fontSize: "32px" }}>
            We Provide Best Services
          </h4>
        </div>
        <Slider {...settings}>
          {cards.map((card, index) => (
            <div key={index} className="p-2">
              <div
                className="card"
                style={{
                  width: "100%",
                  maxWidth: "350px",
                  margin: "0 auto",
                  height: "100%",
                  border: "none",
                  borderRadius: "0px",
                  maxHeight: "400px",
                  background:"none"
                }}
              >
               <div style={{width:"100%",height:"300px",backgroundSize:"cover",backgroundRepeat:"no-repeat",}}>
               <img
               src={card.img}
              //  className="card-img-top"
               alt="..."
               style={{objectFit:"cover",width:"100%",height:"100%"}}
             />
               </div>
                <div className="p-3" style={{ backgroundColor: "#FFA9BE" }}>
                  <p className="card-text  pt-1 pb-1 fs-6 text-center  " style={{ color: "#C71585" }}>{card.text}</p>

                </div>
                <div
                  className="card-body"
                  style={{ backgroundColor: card.backgroundColor }}
                >
                  <p className="card-text  pt-1 pb-1 ">{card.text}</p>
                  <p className="card-text1  pt-1 pb-1 ">{card.des}</p>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </section>
  );
};

export default ResponsiveSlider;
