// import React from 'react';
// import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
// import Landing from './Compontes/Landing';
// import LoginScreen from './Compontes/LoginScreen';
// import LoginOtp from './Compontes/LoginOtp';
// import Home from './Compontes/MainCom/Home';
// import UserProfile from './Compontes/MainCom/UserProfile';
// import DroctotCat from './Compontes/MainCom/DroctotCat';
// import Hospitlas from './Compontes/MainCom/Hospitlas';
// import CategeroyCards from './Compontes/MainCom/CategeroyCards';
// import SignUp from './Compontes/SignUp';

// function PrivateRoute({ children }) {
//   const token = localStorage.getItem("token");
//   console.log(token,"fdfssdfsfds")
//   return token ? children : <Navigate to="/landing" />;
// }

// function App() {
//   return (
//     <Router>
//       <Routes>
//         <Route path="/" element={<Navigate to={localStorage.getItem("token") ? "/home" : "/landing"} />} />
//         <Route path="/landing" element={<Landing />} />
//         <Route path="/loginNumber" element={<LoginScreen />} />
//         <Route path="/LoginOtp" element={<LoginOtp />} />
//         <Route path="/SignUp" element={<SignUp />} />
        
//         <Route path="/home" element={<PrivateRoute><Home /></PrivateRoute>} />
//         <Route path="/UserProfile" element={<PrivateRoute><UserProfile /></PrivateRoute>} />
//         <Route path="/DroctotCat" element={<PrivateRoute><DroctotCat /></PrivateRoute>} />
//         <Route path="/Hospitlas" element={<PrivateRoute><Hospitlas /></PrivateRoute>} />
//         <Route path="/CategeroyCards" element={<PrivateRoute><CategeroyCards /></PrivateRoute>} />
//       </Routes>
//     </Router>
//   );
// }

// export default App;

import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import Landing from './Compontes/Landing';
import LoginScreen from './Compontes/LoginScreen';
import LoginOtp from './Compontes/LoginOtp';
import Home from './Compontes/MainCom/Home';
import UserProfile from './Compontes/MainCom/UserProfile';
import DroctotCat from './Compontes/MainCom/DroctotCat';
import Hospitlas from './Compontes/MainCom/Hospitlas';
import CategeroyCards from './Compontes/MainCom/CategeroyCards';
import SignUp from './Compontes/SignUp';
import Plans from './Compontes/MainCom/Plans';
import UploadImage from './Compontes/MainCom/UploadImage';
import VideoUpload from './Compontes/MainCom/VideoUpload';

function App() {
  const token = localStorage.getItem('token');

  return (
    <Routes>
      <Route path="/" element={<Navigate to={token ? '/home' : '/landing'} />} />
      <Route path="/landing" element={<Landing />} />
      <Route path="/loginNumber" element={<LoginScreen />} />
      <Route path="/LoginOtp" element={<LoginOtp />} />
      <Route path="/home" element={<Home />} />
      <Route path="/UserProfile" element={<UserProfile />} />
      <Route path="/SignUp" element={<SignUp />} />
      <Route path="/DroctotCat" element={<DroctotCat />} />
      <Route path="/Hospitlas" element={<Hospitlas />} />
      <Route path="/CategeroyCards" element={<CategeroyCards />} />
      <Route path="/plans" element={<Plans/>} />
      <Route path="/uploadImage" element={<UploadImage/>} />
      <Route path="/videoUpload" element={<VideoUpload/>} />

      </Routes>
  );
}

export default App;
